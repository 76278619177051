div ul {
  overflow: scroll;
  max-height: 360px;
}

.tooltip-master{
  background-color: #212121 !important;
  padding: 20px !important;
  max-width: 300px !important;
  z-index: 99999999999;
}