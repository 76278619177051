@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,500;1,100;1,300;1,500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

body{
    /* font-family: 'Raleway', sans-serif; */
    font-family: 'Lato', sans-serif;
}